import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from 'app/auth/service';
import { ApiConstant } from 'app/shared/api-constents';
import moment from 'moment';

@Component({
  selector: 'app-job-preview',
  templateUrl: './job-preview.component.html',
  styleUrls: ['./job-preview.component.scss']
})
export class JobPreviewComponent implements OnInit {

  @Output() preview_open_event = new EventEmitter<boolean>();
  @Input() jobDetails:any;
  profile:any;
  constructor(
    private apiService:ApiService
  ) { }

  ngOnInit(): void {    
    console.log(this.jobDetails,"preview");
    
    this.getProfileData()
  }

  weekDay(date){
    moment.locale('ja');
    // Get the current date
    var today = moment(date);
    // Format the date to show the day of the week in Japanese
    var japaneseWeekDay = today.format('dd');
    return japaneseWeekDay
  }

  getProfileData() {
    this.apiService
      .ExecuteGet(this.apiService.baseUrl + ApiConstant.profile)
      .subscribe(
        (response: any) => {
          this.profile = response?.result
        },
        (error: any) => {
        }
      );
  }

  back(){
    this.preview_open_event.emit(false)
  }
}
